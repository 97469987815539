
<template>
  <div
    class="scrollbar-box"
    :style="{ height: height }"
    @mouseleave="hideScroll = false"
    @mouseover="hideScroll = true"
  >
    <div class="scrollbar-y">
      <div
        ref="scrollRef"
        class="scroll-wrap"
        v-resize="resize"
        @scroll="onMosewheel"
      >
        <slot></slot>
      </div>
      <div
        v-show="heightPre < 1"
        ref="barRef"
        class="scrollbar-track"
        v-if="hideScroll"
      >
        <div
          :style="{
            height: barHeight + 'px',
            transform: 'translateY(' + translateY + 'px)',
          }"
          class="scrollbar-thumb"
          @mousedown.stop.prevent="moveStart"
          ref="thumb"
        ></div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  directives: {
    // 使用局部注册指令的方式
    resize: {
      // 指令的名称
      bind(el, binding) {
        // el为绑定的元素，binding为绑定给指令的对象
        let width = "",
          height = "";

        function isReize() {
          const style = document.defaultView.getComputedStyle(el);
          if (width !== style.width || height !== el.scrollHeight) {
            binding.value(); // 关键
          }
          width = style.width;
          height = el.scrollHeight;
        }
        el.__vueSetInterval__ = setInterval(isReize, 300);
      },
      unbind(el) {
        clearInterval(el.__vueSetInterval__);
      },
    },
  },
  props: {
    height: {
      type: String,
      default: "100%",
    },
    isTop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isMove: false,
      trackHeight: 0, // 滚动条轨道高度
      wrapHeight: 0, // 容器高度（可视高度）
      wrapContentHeight: 0, // 内容高度（可滚动内容的高度）
      translateY: 0,
      moveClientY: 0,
      scrollTop: 0,
      hideScroll: false,
    };
  },
  computed: {
    barHeight() {
      return this.heightPre * this.trackHeight;
    },
    // 可视高度与内容高度的比例
    heightPre() {
      return this.wrapHeight / this.wrapContentHeight;
    },
  },
  watch: {
    translateY(e) {
      // console.log(e, 'translateY');
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.initScrollListner();
  },
  activated() {
    //初始化滚动条置顶
    this.translateY = 0;
  },
  methods: {
    initScrollListner(e) {
      let scroll = this.$refs.scrollRef;
      this.wrapContentHeight = scroll.scrollHeight;
      this.wrapHeight = scroll.clientHeight;
      this.trackHeight = scroll.clientHeight;
      if (!this.isTop) {
        scroll.scrollTop = this.wrapContentHeight;
      }
    },
    updateScroll() {
      this.initScrollListner();
    },
    onMosewheel(e) {
      this.scrollY(e.target.scrollTop * this.heightPre);
    },
    scrollY(y) {
      this.translateY = y;
    },
    // 鼠标开始移动
    moveStart(e) {
      this.isMove = true;
      // 开始移动时鼠标距离thumb顶部的位置
      this.moveClientY = e.clientY - this.translateY;
      this.moveTo();
      this.moveEnd();
    },
    // 鼠标移动，改变thumb的位置以及容器scrollTop的位置
    moveTo() {
      document.onmousemove = (e) => {
        if (this.isMove) {
          if (
            e.clientY - this.moveClientY >
            this.trackHeight - this.barHeight
          ) {
            this.translateY = this.trackHeight - this.barHeight;
          } else if (e.clientY - this.moveClientY < 0) {
            this.translateY = 0;
          } else {
            this.translateY = e.clientY - this.moveClientY;
          }
          this.$refs.scrollRef.scrollTop = this.translateY / this.heightPre;
        }
      };
    },
    // 鼠标移动结束
    moveEnd() {
      document.onmouseup = (e) => {
        if (this.isMove) {
          this.isMove = false;
        }
      };
    },
    resize() {
      this.initScrollListner();
    },
    //滚动条移动到顶部
    moveTop() {
      this.hideScroll = true;
      this.$nextTick(() => {
        this.$refs.scrollRef.scrollTop = 0;
      });
    },
    //滚动条置底
    moveBottom() {
      this.hideScroll = true;
      this.$nextTick(() => {
        let scrollRef = this.$refs.scrollRef;
        //滚动条高度
        let height=scrollRef.clientHeight/scrollRef.scrollHeight*scrollRef.clientHeight
        //滚动条最底部（内容高度-可视高度-滚动条高度）
        scrollRef.scrollTop =scrollRef.scrollHeight-scrollRef.clientHeight-height;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.scrollbar-box {
  height: 600px;
  overflow: hidden;
}
.scrollbar-y {
  position: relative;
  height: 100%;
  margin-right: -17px;
  .scroll-wrap {
    height: 100%;
    overflow-y: scroll;
  }
  .scrollbar-track {
    position: absolute;
    top: 0;
    right: 17px;
    bottom: 0;
    width: 16px;
    // background-color: #000000; //背景
    border-radius: 8px;
    z-index: 20;
    .scrollbar-thumb {
      width: 12px;
      margin: 0 auto;
      height: 20%;
      background-color: #eeeeee; //滑块
      border-radius: 6px;
      cursor: default;
      // transition: all 0.01s;
    }
  }
}
</style>